import React from 'react';
import {graphql,StaticQuery} from 'gatsby';
import { Link } from "gatsby"

const MainMenu = () => (
	<StaticQuery query={graphql`
	{
	  allWordpressWpApiMenusMenusItems(filter:{
	    name:{
	      eq:"Menu"
	    }
	  }) {
	    edges {
	      node {
	        items {
	          title
	          object_slug
	        }
	      }
	    }
	  }
	}
	`} render={props => (

	<nav class='mobile'>
		<ul>
        {props.allWordpressWpApiMenusMenusItems.edges[0].node.items.map(item => (
			<li>
				<Link
					key={item.title}
					to={`/${item.object_slug}`}
				>
		            {item.title}
	            </Link>
        	</li>
        ))}
        <li>
			<Link
				key='contact-footer'
				to='#contact'
				class="contactgoto"
			>
	            Contact
            </Link>
        </li>
        </ul>
	</nav>
	)}/>
);

export default MainMenu;