import React from 'react';
import {graphql,StaticQuery} from 'gatsby';
// import { Link } from "gatsby"
import styled, {createGlobalStyle} from 'styled-components';
// import SiteInfo from './siteinfo';
import scrollTo from 'gatsby-plugin-smoothscroll';

function map($url,$webp){

  var map = `
  <div class='map'>
    <picture>
      <source srcset="`+$webp+`" type="image/webp">
      <source srcset="`+$url+`" type="image/jpeg"> 
      <img src="`+$url+`">
    </picture>
  </div>
  `;

  return map;
}



const Footer = styled.footer`
  background:var(--bg-colour);
  width:100%;
  .main-grid {
    min-height:50vh;
  }
  height:auto;
  padding:0.45rem 0;
  @media print, screen and (min-width: 700px) {
    display:inline-block;
      padding:0.9rem 0;
  }
  h3 {
    margin:0 0 0.45em 0;
  }
  .info p {
    font-size:0.9rem;
  }
  .map {
    // z-index: 1;
    // position: relative;
    display:none;
    @media print, screen and (min-width: 700px) {
      display:block;
    }
    picture,
    picture img {
      left:0;
      top:50%;
      transform:translateY(-50%);
      width:100%;
      height:auto;
      position: absolute;
      opacity: 0.9;
    }
  }
  .text-right {
    position:relative;
    z-index:2;
  }
  .grid-spacer {
    margin-top:3.6rem;
    margin-bottom:3.6rem;
    &.small-print {
      border-top:2px solid var(--primary-colour);
      padding-top:1.8rem;
      font-size:0.8rem;
      &,a {color:var(--dark-gray)}
      a {
        text-decoration:none;
        &:hover {
          text-decoration:underline;
        }
      }
    }
  }
  ul {
    list-style:none;
    margin:0;
    padding:0;
    li {
      display:inline-block;
      margin-left:0.9em;
    }
  }
  svg {
    width:auto;
    height:0.9rem;
  }
  button {
    position:relative;
    z-index:99;
    cursor:pointer;
  }

`
const PageFooter = () => (
  <StaticQuery query={graphql`
{
  allWordpressPage(filter: {wordpress_id: {eq: 39}}) {
    edges {
      node {
        acf {
          footer_contact_us
          footer_postal
          small_print_address
          footer_links
          map {
            localFile {
              childImageSharp {
                fixed(width: 800) {
                  src
                  srcWebp
                }
              }
            }
          }
        }
      }
    }
  }
}
  `} render={props => (
  <Footer>
  {props.allWordpressPage.edges.map(page=> (
  <div id="contact" class="grid-container">
    <div class="grid-x main-grid grid-spacer grid-margin-x">
      <div class="cell small-10 medium-6">
        <div class="grid-x grid-margin-x grid-margin-y">
          <div class="cell small-12">
            <h3>Contact Us</h3>
          </div>
          <div class="info cell small-12 medium-6" dangerouslySetInnerHTML={{__html:page.node.acf.footer_contact_us}} />
          <div class="info cell small-12 medium-6" dangerouslySetInnerHTML={{__html:page.node.acf.footer_postal}} />
        </div>
      </div>
      <div class="cell small-2 medium-6 text-right">
        <button class="top" onClick={() => scrollTo('#content')}><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M0 16.67l2.829 2.83 9.175-9.339 9.167 9.339 2.829-2.83-11.996-12.17z"/></svg> Top</button>
          <div 
            dangerouslySetInnerHTML={{__html:map(
              page.node.acf.map.localFile.childImageSharp.fixed.src,
              page.node.acf.map.localFile.childImageSharp.fixed.srcWebp
            )}} 
          />
      </div>
    </div>
    <div class="grid-x grid-spacer small-print">
      <div class="cell small-6" dangerouslySetInnerHTML={{__html:page.node.acf.small_print_address}} />
      <div class="cell small-6 text-right">
        <ul>
          <li> © {new Date().getFullYear()} The Plato Centre.</li>
          <li dangerouslySetInnerHTML={{__html:page.node.acf.footer_links}} />
          <li>Design <a href="https://www.stirtingale.com" target="_blank">Stirtingale</a></li>
        </ul>
      </div>
    </div>
  </div>
  ))}
  </Footer>
  )}/>
);
export default PageFooter;