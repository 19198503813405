import React from 'react';
import {graphql,StaticQuery} from 'gatsby';
import { Link } from "gatsby"
import styled, {createGlobalStyle} from 'styled-components';
import SiteInfo from './siteinfo';

class Burger extends React.Component {
  constructor(props) {
    super(props);
    this.state = {addClass: false}
  }
  toggle() {
    this.setState({addClass: !this.state.addClass});
  }
  render() {
    let burderClass = ["hamburger hamburger--collapse"];
    if(this.state.addClass) {
      	burderClass.push('is-active');
    } else {
    }
    return(
		<button className={burderClass.join(' ')} onClick={this.toggle.bind(this)} type="button"><span class="hamburger-box"><span class="hamburger-inner"></span></span></button>		
    );
  }
}
const MainMenu = () => (
	<StaticQuery query={graphql`
	{
	  allWordpressWpApiMenusMenusItems(filter:{
	    name:{
	      eq:"Menu"
	    }
	  }) {
	    edges {
	      node {
	        items {
	          title
	          object_slug
	        }
	      }
	    }
	  }
	}
	`} render={props => (

	<nav class='main'>
		<SiteInfo />
		<ul>
        {props.allWordpressWpApiMenusMenusItems.edges[0].node.items.map(item => (
			<li>
				<Link
					key={item.title}
					to={`/${item.object_slug}`}
				>
		            {item.title}
	            </Link>
        	</li>
        ))}
        <li>
			<Link
				key='contact-footer'
				to='#contact'
			>
	            Contact
            </Link>
        </li>
        </ul>
		<Burger />	
	</nav>
	)}/>
);

export default MainMenu;