import React from "react"
import { withPrefix, Link } from "gatsby"
import './scss/layout.scss'
import './scss/app.scss'
// CUSTOM BITS
import MainMenu from './mainmenu'
import MobileMenu from './mobilemenu'
import Footer from './footer'
import {Helmet} from "react-helmet";

const Layout = ({ children }) => (
<section id="content">
	<MainMenu /> 
	<MobileMenu />
	<div>
	  {children}
	</div>
	<Footer />
</section>
)

export default Layout
