import React from 'react';
import {graphql, StaticQuery} from 'gatsby';
import styled from 'styled-components';
import { Link } from "gatsby"

const SiteInfoWrapper = styled.div`
  flex-grow:1;
  margin: auto 0;
`

const SiteTitle = styled.div`
  font-weight: bold;
`
const SiteInfo = () => (
  <StaticQuery query={graphql`
    {
      allWordpressSiteMetadata{
        edges{
          node{
            name
            description
          }
        }
      }
    }
  `} render={props => (
    <SiteInfoWrapper>
      <Link to="/" className="logo">
        <img src={'/plato_centre_logo.png'} alt="{props.allWordpressSiteMetadata.edges[0].node.name} {props.allWordpressSiteMetadata.edges[0].node.description}" />
      </Link>
    </SiteInfoWrapper>
  )} />
);

export default SiteInfo;